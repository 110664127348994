import React, { useState, useEffect } from "react";

import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
// import { PageHeader } from "../../components/PageHeader";
import { TagsBody } from "../../components/TagsBody";
import { 
  getActiveTags,
  getActiveArchivedTags,
  getExternalArchivedTags,
  getExternalTags,
  getExternalCategory,
  getActiveCategory
} from "../../lib/api/admin";


import "./style.scss";

export const SettingsPage = () => {
  const [value, setValue] = useState(0);
  const [loading, setLoading] = useState(true);
  const [activeTags, setActiveTags] = useState([]);
  const [externalTags, setExternalTags] = useState([]);
  const [activeArchivedTags, setActiveArchivedTags] = useState([]);
  const [externalArchivedTags, setExternalArchivedTags] = useState([]);
  const multitenancyCompanyShortName = useSelector(
    (state) => state.app.tenantSettings?.company_short_name || ""
  );

  const [activeCategories, setActiveCategories] = useState([]);
  const [externalCategories, setExternalCategories] = useState([]);

  const featureFlags = useSelector(
    (state) => state.app.tenantSettings?.feature_flags || []
  );

  const cshEnabled = featureFlags.some(
    (flag) => flag.name === "customer_service_handling" && flag.is_enabled
  );

  useEffect(() => {
    (async () => {
      await fetchCategories();
      await fetchActiveTags();
      await fetchExternalTags();
      await fetchActiveArchivedTags();
      await fetchExternalArchivedTags();
      setLoading(false);
    })();
  }, []);

  const fetchCategories = async () => {
    const activeCat = await getActiveCategory();
    const externalCat = await getExternalCategory();
    setActiveCategories(activeCat.data);
    setExternalCategories(externalCat.data);
  }

  const fetchActiveTags = async () => {
    const activeTags = await getActiveTags();
    setActiveTags(activeTags.data);
  };

  const fetchExternalTags = async () => {
    const tags = await getExternalTags();
    setExternalTags(tags.data);
  };

  const fetchActiveArchivedTags = async () => {
    const activeArchivedTags = await getActiveArchivedTags();
    setActiveArchivedTags(activeArchivedTags.data);
  };

  const fetchExternalArchivedTags = async () => {
    const externalArchivedTags = await getExternalArchivedTags();
    setExternalArchivedTags(externalArchivedTags.data);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const TabPanel = ({ children, value, index }) => {
    return (
      <div role="tabpanel" hidden={value !== index}>
        {value === index && <div>{children}</div>}
      </div>
    );
  };

  return (
    <div className="default-container settings-page">
      <Helmet>
        <title>Settings – {multitenancyCompanyShortName}</title>
      </Helmet>
      {/* <PageHeader title="Settings" /> */}
      <div className="title">Tags</div>
      <div className="tab-container">
        <div className="tab-container__column">
          <h2 className="heading heading-2">Notes Tags</h2>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            onChange={handleTabChange}
            aria-label="Vertical tabs"
            className="tab-container__tabs"
          >
            <Tab label="Current Tags" id="vertical-tab-current" />
            {cshEnabled && (
              <Tab label="External Tags" id="vertical-tab-archived" />
            )}
            <Tab label="Archived Current Tags" id="vertical-tab-archived" />
            {cshEnabled && (
              <Tab label="Archived External Tags" id="vertical-tab-archived" />
            )}
          </Tabs>
        </div>
        <div className="tab-container__column tab-container__column--wide">
          <TabPanel value={value} index={0}>
            <TagsBody
              headerTitle={"Current Tags"}
              activeTags={activeTags}
              loading={loading}
              type="active"
              fetchActiveTags={fetchActiveTags}
              fetchExternalTags={fetchExternalTags}
              fetchActiveArchivedTags={fetchActiveArchivedTags}
              fetchExternalArchivedTags={fetchExternalArchivedTags}
              activeCategories={activeCategories}
              externalCategories={externalCategories}
              showCategories={activeCategories}
            />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <TagsBody
              headerTitle={"External Tags"}
              externalTags={externalTags}
              loading={loading}
              type="external"
              fetchActiveTags={fetchActiveTags}
              fetchExternalTags={fetchExternalTags}
              fetchActiveArchivedTags={fetchActiveArchivedTags}
              fetchExternalArchivedTags={fetchExternalArchivedTags}
              activeCategories={activeCategories}
              externalCategories={externalCategories}
              showCategories={externalCategories}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <TagsBody
              headerTitle={"Archived Current Tags"}
              archivedTags={activeArchivedTags}
              type="active"
              fetchActiveTags={fetchActiveTags}
              fetchExternalTags={fetchExternalTags}
              fetchActiveArchivedTags={fetchActiveArchivedTags}
              fetchExternalArchivedTags={fetchExternalArchivedTags}
              loading={loading}
              activeCategories={activeCategories}
              externalCategories={externalCategories}
              showCategories={activeCategories}
            />
          </TabPanel>
          <TabPanel value={value} index={3}>
            <TagsBody
              headerTitle={"Archived External Tags"}
              archivedTags={externalArchivedTags}
              type="external"
              fetchActiveTags={fetchActiveTags}
              fetchExternalTags={fetchExternalTags}
              fetchActiveArchivedTags={fetchActiveArchivedTags}
              fetchExternalArchivedTags={fetchExternalArchivedTags}
              loading={loading}
              activeCategories={activeCategories}
              externalCategories={externalCategories}
              showCategories={externalCategories}
            />
          </TabPanel>
        </div>
      </div>
    </div>
  );
};

SettingsPage.propTypes = {
  children: PropTypes.array,
  value: PropTypes.string,
  index: PropTypes.string,
};
